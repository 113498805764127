<template>
  <div style="background: #f6f6f6; color: #000">
    <van-nav-bar title="业务管理" left-arrow :fixed="true" :z-index="999">
      <template #left>
        <van-icon @click="back" name="arrow-left" />
        <img style="width: 22px; margin-left: 10px" @click="tourlewm" src="../../../assets/img/erwm.png" alt="" />
        <img style="width: 22px; margin-left: 15px" @click="towxtz" src="../../../assets/img/wwssx.png" alt="" />
      </template>
      <template #right>
        <img style="width: 26px; margin-right: 15px" @click="copy" src="../../../assets/img/jielong.png" alt="" />
        <img style="width: 26px; margin-right: 15px" @click="tourl" src="../../../assets/img/zandan.png" alt="" />
        <img style="width: 22px" @click="tobx" src="../../../assets/img/bbxx.png" alt="" />
      </template>
    </van-nav-bar>
    <div class="nav-bar">
      <van-row class="topss">
        <van-col span="4" @click="setacv(0)" :class="acv == 0 ? 'red' : ''">未结</van-col>
        <van-col span="5" @click="setacv(1)" :class="acv == 1 ? 'red' : ''">已结</van-col>
        <van-col span="5" @click="setacv(2)" :class="acv == 2 ? 'red' : ''">全部</van-col>
        <van-col span="5" @click="setacv(5)" :class="acv == 5 ? 'red' : ''">业务员</van-col>
        <van-col span="5" @click="setacv(3)" :class="acv == 3 || acv == 4 ? 'red' : ''">油漆</van-col>
      </van-row>
      <div class="flxtop" v-if="acv == 0">
        <div class="f1">
          <div class="bjs">合计金额</div>
          <div class="jinr" style="color: red">
            {{ numForm.total ? numForm.total.toFixed(0) : 0 }}
          </div>
        </div>
        <div class="f1" @click="tourltop('自费车辆', 1)">
          <div class="bjs">自费车辆</div>
          <div class="jinr">{{ numForm.zf ? numForm.zf.toFixed(0) : 0 }}</div>
        </div>
        <div class="f1" @click="tourltop('事故车辆', 2)">
          <div class="bjs">事故车辆</div>
          <div class="jinr">{{ numForm.sg ? numForm.sg.toFixed(0) : 0 }}</div>
        </div>
        <div class="f1" @click="tourltop('未到账', 3)">
          <div class="bjs" style="background-color: #f7e2e2; color: #f84949">
            未到账
          </div>
          <div class="jinr">
            {{ numForm.sgwdz ? numForm.sgwdz.toFixed(0) : 0 }}
          </div>
        </div>
        <div class="f1" @click="tourltop('定损未出', 4)">
          <div class="bjs" style="background-color: #e2f7e4; color: #16b766">
            定损未出
          </div>
          <div class="jinr">
            {{ numForm.sgwc ? numForm.sgwc.toFixed(0) : 0
            }}<span class="lanse" style="color: #000">|</span>
            <span class="zuoc" style="color: red">{{ numForm.dswc }}</span>
          </div>
        </div>
      </div>
      <div class="fsdd" v-if="acv == 0 || acv == 1 || acv == 2">
        <van-checkbox icon-size="18px" checked-color="#0d906e" @change="onSearch" shape="square"
          v-model="typeA">3日内</van-checkbox>
        <van-checkbox icon-size="18px" checked-color="#0d906e" @change="onSearch" shape="square"
          style="margin-left: 6px" v-model="typeB">3日以上</van-checkbox>
        <van-search v-model="key" show-action placeholder="请输入搜索关键词" @search="onSearch">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>

      <div class="flsx" v-if="acv == 2 || acv == 3 || acv == 5">
        <van-icon @click="timeshang" name="play" style="transform: rotate(180deg)" />
        <span @click="setstiem">{{ stime }} ~ {{ etime }}</span>

        <van-icon @click="timexia" name="play" />
      </div>
      <div class="flsxsss" v-if="acv == 4">
        <span @click="setstiem" style="flex: 1; text-align: center">{{ stime }} ~ {{ etime }}</span>
        <div style="font-weight: bold" @click="acv = 3">
          <span style="margin-right: 10px">￥{{ price }}</span>
          <span>列表</span>
        </div>
      </div>
      <!-- <div class="flsxsss" v-if="acv == 5">
        <span @click="setstiem" style="flex: 1; text-align: center">{{ stime }} ~ {{ etime }}</span>
      </div> -->
      <van-row class="hedt" v-if="acv == 0">
        <van-col span="6">名称</van-col>
        <!-- <van-col span="4">业务员</van-col> -->
        <van-col span="5" style="text-align: right">自费车</van-col>
        <van-col span="5" style="text-align: right">
          <div class="sgdiv">事故车</div>
          <div class="sgdiv ts">(未返点)</div>
        </van-col>
        <van-col span="4">
          <div class="sgdiv">事故车</div>
          <div class="sgdiv ts" style="color: red">(未出)</div>
        </van-col>
        <van-col span="4">应收额</van-col>
      </van-row>
      <van-row class="hedt" v-else-if="acv == 1">
        <van-col span="10">名称</van-col>
        <van-col span="5">业务员</van-col>
        <van-col span="9">已结笔数</van-col>
      </van-row>
      <van-row class="hedt" v-else-if="acv == 2">
        <van-col span="6">名称</van-col>
        <van-col span="4">业务员</van-col>
        <van-col span="5" style="padding-right: 5px">自费</van-col>
        <van-col span="5">事故车</van-col>
        <van-col span="4">未出</van-col>
      </van-row>
      <van-row class="hedt" v-else-if="acv == 3">
        <van-col span="6">油漆单价</van-col>
        <van-col span="6">数量</van-col>
        <van-col span="6" style="padding-right: 5px">金额</van-col>
        <van-col span="6">占比</van-col>
      </van-row>
      <van-row class="hedt" v-else-if="acv == 4">
        <van-col span="6">车牌</van-col>
        <van-col span="5">车型</van-col>
        <van-col span="6" style="padding-right: 5px">业务来源</van-col>
        <van-col span="4">业务员</van-col>
        <van-col span="3">数量</van-col>
      </van-row>
      <van-row class="hedt font_14" v-else-if="acv == 5">
        <!-- <van-col span="3">姓名</van-col>
        <van-col span="9">本月应收|欠款</van-col>
        <van-col span="4">自费款</van-col>
        <van-col span="4">事故款</van-col>
        <van-col span="4">钣金费</van-col> -->
        <van-col span="3">姓名</van-col>
        <van-col span="3" class="font_14">已审核</van-col>
        <van-col span="3">合计 <br>(元)</van-col>
        <van-col span="4">自费车 <br>(元)</van-col>
        <van-col span="4">事故车 <br>(元)</van-col>
        <van-col span="3">毛利 <br>(元)</van-col>
        <van-col span="4" class="tr">提成 <br>(元)</van-col>
      </van-row>
      <div class="flxtop" v-if="acv == 6">
        <div class="f1">
          <div class="bjs">合计金额</div>
          <div class="jinr" style="color: red">
            {{ numForm.total ? numForm.total.toFixed(0) : 0 }}
          </div>
        </div>
        <div class="f1" @click="tourltop('自费车辆', 1)">
          <div class="bjs">自费车辆</div>
          <div class="jinr">{{ numForm.zf ? numForm.zf.toFixed(0) : 0 }}</div>
        </div>
        <div class="f1" @click="tourltop('事故车辆', 2)">
          <div class="bjs">事故车辆</div>
          <div class="jinr">{{ numForm.sg ? numForm.sg.toFixed(0) : 0 }}</div>
        </div>
        <div class="f1" @click="tourltop('未到账', 3)">
          <div class="bjs" style="background-color: #f7e2e2; color: #f84949">
            未到账
          </div>
          <div class="jinr">
            {{ numForm.sgwdz ? numForm.sgwdz.toFixed(0) : 0 }}
          </div>
        </div>
        <div class="f1" @click="tourltop('定损未出', 4)">
          <div class="bjs" style="background-color: #e2f7e4; color: #16b766">
            定损未出
          </div>
          <div class="jinr">
            {{ numForm.sgwc ? numForm.sgwc.toFixed(0) : 0
            }}<span class="lanse" style="color: #000">|</span>
            <span class="zuoc" style="color: red">{{ numForm.dswc }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="pt" v-if="acv == 2">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads"
        :offset="10">
        <div class="mian" v-for="(item, idx) in list" :key="idx" @click="tourls(item, 0)">
          <van-row class="mian_row">
            <van-col span="6">{{ item.source }}</van-col>
            <van-col span="4">{{ item.sellsMan }}</van-col>
            <van-col span="5" style="padding-right: 5px">{{ item.zf ? item.zf.toFixed(0) : 0
              }}<span class="lanse">|</span>
              <span class="zuoc">{{ item.zfun }}</span>
            </van-col>
            <van-col span="5">{{ item.sg ? item.sg.toFixed(0) : 0 }}<span class="lanse">|</span><span class="zuoc">{{
                item.sgun }}</span>
            </van-col>
            <van-col span="4">{{ item.un }} </van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <div class="pt" v-else-if="acv == 3">
      <div class="mian" v-for="(item, idx) in yqlist" :key="idx" @click="tolist(item)">
        <van-row class="mian_row">
          <van-col span="6">{{ item.price }}</van-col>
          <van-col span="6" style="color: #010bd0">{{ item.counts }}</van-col>
          <van-col span="6" style="padding-right: 5px">{{
            item.total
            }}</van-col>
          <van-col span="5">{{ item.rate }}% </van-col>
          <van-col span="1"><van-icon v-if="idx + 1 != yqlist.length" name="arrow" />
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="pt" v-else-if="acv == 4">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads"
        :offset="10">
        <div class="mian" v-for="(item, idx) in xqlist" :key="idx" @click="tourls(item, 0)">
          <van-row class="mian_row">
            <van-col span="5">{{ item.carNo }}</van-col>
            <van-col span="7" style="padding-right: 5px">{{
              item.source
              }}</van-col>
            <van-col span="4">{{ item.carModel }}</van-col>
            <van-col span="4">{{ item.sellsMan }} </van-col>
            <van-col span="4">{{ item.counts }} </van-col>
          </van-row>
        </div>
      </van-list>
      <van-row class="mian_row ding" style="line-height: 35px">
        <van-col span="6">合计:</van-col>
        <van-col span="5">{{ dform.carModel }}</van-col>
        <van-col span="6" style="padding-right: 5px">{{
          dform.source
          }}</van-col>
        <van-col span="4">{{ dform.sellsMan }} </van-col>
        <van-col span="3">{{ dform.counts }} </van-col>
      </van-row>
    </div>
    <div class="pt" v-else-if="acv == 5">
      <div class="mian" v-for="(item, idx) in ywylist" :key="idx" @click="toywy(item, idx,ywylist.length)">
        <van-row class="mian_row pr">
          <van-col span="3">{{ item.sellsMan }}</van-col>
          <van-col span="3" class="cRed">{{ item.unpaidPayments ? item.unpaidPayments.toFixed(0) : 0 }}</van-col>
          <van-col span="3">{{ item.amountTo ? item.amountTo.toFixed(0) : 0 }} </van-col>
          <van-col span="4">{{ item.selfPaidCar ? item.selfPaidCar.toFixed(0) : 0 }}</van-col>
          <van-col span="4">{{ item.accidentCar ? item.accidentCar.toFixed(0) : 0 }} </van-col>
          <van-col span="3" class="cBlue font_13">{{ item.grossMargin }} </van-col>
          <van-col span="4" class="tr">{{ item.commission ? item.commission.toFixed(0) : 0 }}&nbsp;</van-col>
          <van-col span="1" class="tr iconroow"><van-icon v-if="item.sellsMan != '合计'" name="arrow" />&nbsp;</van-col>
          <!-- <van-col span="9" class="flex-center">
            <span>{{ item.thisMonthReceivable ? item.thisMonthReceivable.toFixed(0) : 0  }}</span>
            <span class="lanse">|</span>
            <span class="zuoc cRed">{{ item.thisMonthDebt }}</span>
          </van-col>
          <van-col span="4">{{ item.selfFunded }}</van-col>
          <van-col span="4">{{ item.accident }} </van-col>
          <van-col span="4">{{ item.bjAmount }}
            <van-icon v-if="idx + 1 != ywylist.length" name="arrow" />
          </van-col> -->
        </van-row>
      </div>
    </div>
    <div class="pt" v-else>
      <div class="mian" v-for="(item, idx) in list" :key="idx" @click="tourls(item, 0)">
        <div v-if="acv == 0">
          <van-row class="mian_row" v-if="idx + 1 != list.length">
            <van-col span="6">{{ item.source }}</van-col>
            <!-- <van-col span="4">
              <span v-if="idx + 1 != list.length">{{ item.sellsMan }}</span>

            </van-col> -->
            <van-col span="5" style="text-align: right">{{ item.amount ? item.amount.toFixed(0) : 0
              }}<span class="lanse">|</span><span class="zuoc">{{ item.counts }}</span></van-col>
            <van-col span="5" style="text-align: right">{{ item.sgAmount ? item.sgAmount.toFixed(0) : 0
              }}<span class="lanse">|</span><span class="zuoc">{{ item.orderCounts }} </span></van-col>
            <van-col span="4">{{ item.billCounts }}</van-col>
            <van-col span="3">
              <span v-if="idx + 1 != list.length">{{
                item.ys ? item.ys.toFixed(0) : 0
                }}</span>
            </van-col>
            <van-col span="1"><van-icon name="arrow" v-if="idx + 1 != list.length" /></van-col>
          </van-row>
          <van-row class="mian_row ding" v-else>
            <van-col span="6">{{ item.source }}</van-col>

            <van-col span="5" style="text-align: right">{{
              item.amount ? item.amount.toFixed(0) : 0
              }}</van-col>
            <van-col span="5" style="text-align: right">{{
              item.sgAmount ? item.sgAmount.toFixed(0) : 0
              }}</van-col>
            <van-col span="4">{{ item.billCounts }}</van-col>
            <van-col span="4">
              <span>{{ item.ys ? item.ys.toFixed(0) : 0 }}</span>
            </van-col>
            <!-- <van-col span="1"><van-icon name="arrow" v-if="idx + 1 != list.length" /></van-col> -->
          </van-row>
        </div>

        <van-row class="mian_row" v-else>
          <van-col span="10">{{ item.source }}</van-col>
          <van-col span="5">{{ item.sellsMan }}</van-col>
          <van-col span="8">{{ item.counts }}</van-col>
          <van-col span="1"><van-icon name="arrow" /></van-col>
        </van-row>
      </div>
      <div style="height: 40px"></div>
    </div>
    <van-row class="mian_row boots" v-if="acv == 2 && list.length > 0">
      <van-col span="10" style="font-weight: bold">合计</van-col>
      <!-- <van-col span="4">{{ bform.sellsMan }}</van-col> -->
      <van-col span="5" style="padding-right: 5px">{{ bform.zf ? bform.zf.toFixed(0) : 0 }}
      </van-col>
      <van-col span="5">{{ bform.sg ? bform.sg.toFixed(0) : 0 }}</van-col>
      <van-col span="4">{{ bform.un }}</van-col>
    </van-row>
    <van-popup :close-on-click-overlay="false" v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow = false" @confirm="settiem" v-model="currentDate" type="year-month"
        :title="timename" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
    <van-empty v-if="list.length < 1 && acv !=5 " description="暂无数据~" />
  </div>
</template>

<script>
import {
  source,
  bpjielong,
  sourceall,
  vehiclesource,
  sourceamount,
  factoryyq,
  factoryyqlist,
  sendmsg,
  ywyapi,
  ywyDetail,
  ywyTongji
} from "@/api/check";
import moment from "moment";
export default {
  data() {
    return {
      list: [], //
      bform: {},
      numForm: {},
      gid: this.$route.query.gid,
      timeshow: false,
      xqlist: [],
      dform: {
        carModel: "",
        source: "",
        sellsMan: "",
        counts: "",
      },
      currentDate: new Date(),
      key: "",
      stime: moment().format("YYYY-MM"),
      etime: moment().format("YYYY-MM"),
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 10, 1),
      timename: "选择起始年月",
      acv: 0,
      yqlist: [],
      ywylist: [],
      page: 0,
      price: 0,
      size: 15,
      typeB: true,
      typeA: true,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
      isok: false,
      token: "",
    };
  },
  methods: {
    tourls(item, type) {
      this.$router.push({
        path: "/paint_list",
        query: {
          gid: this.gid,
          source: item.source,
          acv: this.acv,
          names: this.acv == 0 ? "未结" : "已结",
          sellsMan: item.sellsMan,
          type: type,
          typeA: this.typeA,
          typeB: this.typeB,
        },
      });
    },
    tolist(item) {
      this.xqlist = [];
      this.page = 0;

      this.price = item.price;
      this.acv = 4;
      this.getfactoryyqlist();
    },
    toywy(item,index,length) {
      if(index+1 == length) return
      let params = {
        name: item.sellsMan,
        etime: this.etime,
        stime: this.stime,
        gid: this.gid,
      };
      this.$router.push({
        path: "/paint_yewuyuan",
        query: params,
      });
      // ywyDetail(params).then((res) => {
      //   if (res.code == 200) {
      //     this.ywyDetailObj = res.data;
      //   }
      // });
    },
    tourltop(source, type) {
      this.$router.push({
        path: "/paint_list_details",
        query: {
          gid: this.gid,
          source: source,
          acv: this.acv,
          names: "未结",
          sellsMan: "",
          type: type,
          typeA: this.typeA,
          typeB: this.typeB,
        },
      });
    },
    setstiem() {
      this.timename = "选择起始年月";
      this.timeshow = true;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    settiem(e) {
      if (this.timename == "选择起始年月") {
        this.stime = moment(e).format("YYYY-MM");
        this.timeshow = false;

        setTimeout(() => {
          this.timename = "选择截止年月";
          this.timeshow = true;
        }, 500);
      } else {
        this.etime = moment(e).format("YYYY-MM");
        this.page = 0;
        this.total = 0;
        this.list = [];
        this.getlistowt();
        this.timeshow = false;
      }
    },
    copy() {
      var data = {
        gid: this.gid,
        // gid: 506,
        time: moment().format("YYYY-MM-DD"),
        // time: '2023-06-05',
      };
      bpjielong(data).then((e) => {
        if (e.code == 200) {
          console.log(e);
          // for(var i in e.data){
          //     e.data[i]+='\r\n'
          // }
          // console.log(e.data)
          // return
          // var order = '姓名:张三\r\n年龄:24'
          if (e.data == "") {
            this.$toast("没有内容复制");
            return;
          }
          var order = e.data;
          var textarea = document.createElement("textarea"); // 创建textarea对象
          textarea.value = order; // 设置复制内容
          document.body.appendChild(textarea); // 添加临时实例
          textarea.select(); // 选择实例内容
          document.execCommand("Copy"); // 执行复制
          document.body.removeChild(textarea); // 删除临时实例
          console.log("复制成功！");
          this.$toast.success("复制成功！");
        }
      });
    },
    tourl() {
      this.$router.push({
        path: "/bill_list",
        query: {
          gid: this.gid,
        },
      });
    },
    towxtz() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认通知？",
        })
        .then(() => {
          sendmsg({ gid: this.gid }).then((res) => {
            if (res.code == 200) {
              if (res.data) {
                this.$toast.success("通知成功");
              }
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    tourlewm() {
      this.$router.push({
        path: "/bill_ewm",
        query: {
          gid: this.gid,
        },
      });
    },
    tobx() {
      this.$router.push({
        path: "/bx_list",
        query: {
          gid: this.gid,
        },
      });
    },
    timeshang() {
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.stime = moment(this.stime)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM");
      this.etime = this.stime;
      this.getlistowt();
    },
    timexia() {
      this.page = 0;
      this.list = [];
      this.total = 0;
      this.etime = moment(this.etime)
        .startOf("month")
        .subtract("months", -1)
        .format("YYYY-MM");
      this.stime = this.etime;
      this.getlistowt();
    },
    onSearch() {
      if (this.acv == 0) {
        this.getlist();
      } else if (this.acv == 1) {
        this.getlistB();
      } else {
        this.getlistowt();
      }
    },
    setacv(type) {
      this.acv = type;
      this.list = [];
      this.page = 0;
      this.finished = false;
      if (type == 0) {
        this.getlist();
        this.getnum();
      } else if (type == 1) {
        this.getlistB();
      } else if (type == 2) {
        this.getlistowt();
      } else if (type == 3) {
        this.getfactoryyq();
      } else if (type == 5) {
        this.getywy();
      }
    },
    // 获取业务员列表
    getywy() {
      var data = {
        etime: this.etime,
        stime: this.stime,
        gid: this.gid,
      };
      ywyTongji(data).then((e) => {
        if (e.code == 200) {
          this.ywylist = e.data;
        }
      });
    },
    //油漆
    getfactoryyq() {
      var data = {
        etime: this.etime,
        stime: this.stime,
        gid: this.gid,
      };
      factoryyq(data).then((e) => {
        if (e.code == 200) {
          this.yqlist = e.data;
        }
      });
    },
    onLoads() {
      this.page++;
      this.getlistowt();
    },
    getfactoryyqlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中1...",
      });
      var data = {
        gid: this.gid,
        price: this.price,
        stime: this.stime,
        etime: this.etime,
        page: this.page,
        size: this.size,
      };
      factoryyqlist(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e);
          this.loading = false;
          let rows = e.data.page.content;
          this.total = e.data.page.totalElements;
          this.dform = e.data.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          } else {
            this.finished = false;
          }
          // 将新数据与老数据进行合并
          this.bform = e.data.total;
          this.xqlist = this.xqlist.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载

          if (this.xqlist.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },
    getlistowt() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中2...",
      });
      var data = {
        gid: this.gid,
        source: this.key,
        pay: this.acv,
        page: this.page,
        size: this.size,
        stime: this.stime,
        etime: this.etime,
      };
      sourceall(data).then((e) => {
        loading.clear();
        console.log(e);
        if (e.code == 200) {
          this.loading = false;
          let rows = e.data.page.content;
          this.total = e.data.page.totalElements;

          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          } else {
            this.finished = false;
          }
          // 将新数据与老数据进行合并
          this.bform = e.data.total;
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          console.log(this.list.length);
          console.log(this.total);
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },
    getlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中3...",
      });
      var type = 0;
      if (this.typeA && this.typeB) {
        type = 0;
      } else if (this.typeA) {
        type = 1;
      } else {
        type = 2;
      }
      var data = {
        gid: this.gid,
        source: this.key,
        type: type,
        day: 3,
      };
      vehiclesource(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          this.list = e.data;
          this.getnum();
        }
      });
    },
    //统计数量
    getnum() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中4...",
      });
      var type = 0;
      if (this.typeA && this.typeB) {
        type = 0;
      } else if (this.typeA) {
        type = 1;
      } else {
        type = 2;
      }
      var data = {
        gid: this.gid,
        source: this.key,
        type: type,
        day: 3,
      };
      sourceamount(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          this.numForm = e.data;
        }
      });
    },
    getlistB() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中5...",
      });
      var data = {
        gid: this.gid,
        source: this.key,
        pay: this.acv,
      };
      source(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          this.list = e.data;
        }
      });
    },
    back() {
      window.androidjs.onCallbackIntent("MainActivity");
      // this.$router.go(-1);
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    //
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (this.isiOS) {
          this.isok = false;
          this.setupWebViewJavascriptBridge((bridge) => {
            var token = bridge.callHandler(
              "getToken",
              null,
              function responseCallback(responseData) {
                localStorage.setItem("Authorization", responseData);
                _this.token = responseData;
                // alert('走了这里' +  _this.token)
                _this.getlist();
  
                return responseData;
              }
            );
          });
          console.log("ios");
        } else {
          this.isok = true;
          _this.token = window.androidjs.getLocalData("getToken");
          // _this.token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDk3IiwiZXhwIjoxNzM2MzQ0OTU2fQ.vjAPowX_nV1Vi6dcY2PlaW6uPtNk5EIbFRXFqWdExRVCdGW6f3lc6nXd3BJFNhAiizuPgcxSTp4XwHbw30D80g";
          localStorage.setItem("Authorization", _this.token);
          _this.getlist();
          console.log("andriod");
        }
      } catch (error) {
        _this.token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDk3IiwiZXhwIjoxNzM5NTExNjM3fQ.H9VED5MLq_lGxSVbtuGgQvAGTpz4MePrevma__-ZJos9QCrWDYIULnogSFWg6WAUbh_nM7rq8PrCsl3CvDzOwQ";
        localStorage.setItem("Authorization", _this.token);
        _this.getlist();
        console.log("token====",_this.token);
      }
    },
  },
  created() {
    // this.getlist();
    //判断是否在微信环境

    this.appSource();
  },
  mounted() { },
  computed: {},
};
</script>
<style>
body {
  background: #f6f6f6;
}
</style>
<style lang="less" scoped>
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.fsdd {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;

  .van-search {
    flex: 1;
  }
}

.lanse {
  color: #d80d0d;
  font-weight: bold;
  font-size: 16px;
  padding: 0 2px;
}

.cRed {
  color: #d80d0d;
}

.zuoc {
  width: 15px;
  display: inline-block;
  text-align: left;
}

.boots {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.flsx {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 40px;
}

.flsxsss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 40px;
  padding: 0 12px;
}

.topss {
  line-height: 35px;
  font-size: 17px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  color: #666;
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  // line-height: 35px;

  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 5px 10px;
  background: #fff;

  // padding-top: 46px;
  .sgdiv {}

  .ts {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #666;
    transform: scale(0.9);
  }
}

.flx {
  display: flex;
  align-items: center;
}

.flxtop {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #f5f5f5;

  .f1 {
    flex: 1;

    .bjs {
      background-color: #f5f5f5;
      display: inline-block;
      padding: 0 2px;
      border-radius: 2px;
      line-height: 18px;
    }

    .jinr {
      font-size: 14px;
    }
  }
}

.pt {
  padding-top: 46px;
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}

.mian_row {
  // line-height: 35px;

  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  background: #fff;
}
.iconroow{
  position: absolute;
  right: 0;
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}

.ding {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
}

.flex-center {
  padding-right: 10px;
}
</style>
